import React from 'react'
import { graphql, Link } from 'gatsby'


import SEO from "../components/seo"
import Header from "../components/header"

import Footer from "../components/footer"




import Img from 'gatsby-image'

export default ({ data, pageContext: { node, locale, pagePath } }) => {

    let globalData = data.globalData.edges[0].node
    let pageData = data.pageData

    let catalogueImage = data.pageData

    let {
        img_a,
        img_b,
        img_c,
        img_d,
        img_e
    } = catalogueImage.frontmatter

    let page_title_local = globalData.frontmatter[`${locale}_door_series`] + ' ' + node["seriesName"] + " | " + globalData.frontmatter[locale + "_page_title_wooden_doors_prefix"] + " | " + globalData.frontmatter[locale + "_page_title_global_prefix"] + " " + globalData.frontmatter[locale + "_page_title_since_2001_prefix"]  
    let page_meta_description = pageData.frontmatter[locale + "_meta_desc"]
    let page_meta_keywords = pageData.frontmatter[locale + "_meta_keywords"]



    return (
        <>
            <SEO 
                description={page_meta_description}
                keywords={page_meta_keywords}
                pathname={pagePath}
                title={page_title_local}
            />

            <Header 
                locale={locale}
                pagePath={pagePath}
             />
 




            <div className="container">


                <div className="row">
                    <div className="col-12 col-sm-6">
                        <div id="productPageCarousel" className="carousel slide" data-ride="carousel">
                                <div className="carousel-inner">
                                    <div id="productPageCarouselItem" className="carousel-item active">
                                        <Img alt={page_meta_description} fixed={img_a.childImageSharp.fixed} />
                                    </div>
                                    <div id="productPageCarouselItem" className="carousel-item">
                                        <Img alt={page_meta_description} fixed={img_b.childImageSharp.fixed} />
                                    </div>
                                    <div id="productPageCarouselItem" className="carousel-item">
                                        <Img alt={page_meta_description} fixed={img_c.childImageSharp.fixed} />
                                    </div>
                                    <div id="productPageCarouselItem" className="carousel-item">
                                        <Img alt={page_meta_description} fixed={img_d.childImageSharp.fixed} />
                                    </div>
                                    <div id="productPageCarouselItem" className="carousel-item">
                                        <Img alt={page_meta_description} fixed={img_e.childImageSharp.fixed} />
                                    </div>
                                </div>

                                <a className="carousel-control-prev" href="#productPageCarousel" role="button" data-slide="prev">
                                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                    <span className="sr-only">Previous</span>
                                </a>
                                <a className="carousel-control-next" href="#productPageCarousel" role="button" data-slide="next">
                                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                    <span className="sr-only">Next</span>
                                </a>
                        </div>

                    </div>

                    <div className="col-12 col-sm-6">
                        <h1>{globalData.frontmatter[`${locale}_door_series`]} {node["seriesName"]} </h1>
                        <p>{node[locale]}</p>


                        <hr />
                        <Link className="moreInThisSeries" to={`${locale === "he" ? '' : locale}/catalogue/${node.slug.split("/")[0]}`}>
                            {globalData.frontmatter[`${locale}_more_in_this_series`] }
                        </Link>
                        <Link className="backToCatalogue" to={`${locale === "he" ? '' : locale}/catalogue/`}>
                            {globalData.frontmatter[`${locale}_back_to_catalugue`] }
                        </Link>

                    </div>
                </div>
            </div>


            <Footer 
                locale={locale}
                pagePath={pagePath}
            />



        </>
    
    )
}



export const imgQuery = graphql`
    query ImgQuery($imgPath: String) {
        pageData:
        markdownRemark(fields: {imgPath: {eq: $imgPath}}) {
            frontmatter {
                ru_meta_desc
                he_meta_desc
                fr_meta_desc
                en_meta_desc
                es_meta_desc
                pr_meta_desc
                img_a {
                    childImageSharp {
                        fixed(width: 172) {
                            ...GatsbyImageSharpFixed
                        }
                    }
                }
                img_b {
                    childImageSharp {
                        fixed(width: 172) {
                            ...GatsbyImageSharpFixed
                        }
                    }
                }
                img_c {
                    childImageSharp {
                        fixed(width: 172) {
                            ...GatsbyImageSharpFixed
                        }
                    }
                }
                img_d {
                    childImageSharp {
                        fixed(width: 172) {
                            ...GatsbyImageSharpFixed
                        }
                    }
                }
                img_e {
                    childImageSharp {
                        fixed(width: 172) {
                            ...GatsbyImageSharpFixed
                        }
                    }
                }
            }
        }
        globalData: 
        allMarkdownRemark(filter: {frontmatter: {type: {eq: "global_data"}}}) {
            edges {
                node {
                    id
                    frontmatter {
                        he_page_title_global_prefix
                        he_page_title_since_2001_prefix
                        he_page_title_wooden_doors_prefix
                        he_door_series
                        fr_page_title_global_prefix
                        fr_page_title_since_2001_prefix
                        fr_page_title_wooden_doors_prefix
                        fr_door_series
                        en_page_title_global_prefix
                        en_page_title_since_2001_prefix
                        en_page_title_wooden_doors_prefix
                        en_door_series
                        pr_page_title_global_prefix
                        pr_page_title_since_2001_prefix
                        pr_page_title_wooden_doors_prefix
                        pr_door_series
                        es_page_title_global_prefix
                        es_page_title_since_2001_prefix
                        es_page_title_wooden_doors_prefix
                        es_door_series
                        ru_page_title_global_prefix
                        ru_page_title_since_2001_prefix
                        ru_page_title_wooden_doors_prefix
                        ru_door_series
                        he_back_to_catalugue
                        ru_back_to_catalugue
                        en_back_to_catalugue
                        fr_back_to_catalugue
                        es_back_to_catalugue
                        pr_back_to_catalugue
                        he_more_in_this_series
                        ru_more_in_this_series
                        en_more_in_this_series
                        fr_more_in_this_series
                        es_more_in_this_series
                        pr_more_in_this_series
                    }
                }
            }
        }
    }`


